<template>
  <main class="d-flex flex-column white fill-height">
    <header class="header px-5 d-flex justify-space-between align-baseline">
      <div class="d-flex justify-space-between align-baseline">
        <div class="title font-weight-regular">
          Products
        </div>
        <div class="filter-box mx-6">
          <v-text-field
            v-model="search"
            prepend-icon="mdi-magnify"
            label="Filter"
            single-line
            hide-details
          />
        </div>
      </div>
      <v-btn tile large color="light-blue darken-4" class="white--text" min-width="166">
        New Product
      </v-btn>
    </header>
    <section class="flex-grow-1">
      <data-table
        :headers="$options.productsHeadersConfig"
        :items="products"
        :options.sync="options"
        :loading="isLoading"
        :search="search"
      >
        <template v-slot:item.name="{ item }">
          <div class="d-flex align-center">
            <img class="mr-2" src="/icons/product-icon.svg" width="24" height="24" alt="BoxType" />
            <router-link
              class="light-blue--text text--darken-4 font-weight-medium text-decoration-none"
              :to="{ name: 'ProductDetails', params: { id: item.id } }"
            >
              {{ item.name }}
            </router-link>
          </div>
        </template>
        <template v-slot:item.category="{ item }">
          <span class="font-weight-regular text--darken-4">{{ item.category }}</span>
        </template>
      </data-table>
    </section>
  </main>
</template>

<script>
import DataTable from '@/components/common/DataTable.vue';

import { getProductsList } from '@/api/products.api';

const PRODUCT_TABLE_HEADER_CONFIG = [
  {
    text: 'Product Name',
    value: 'name',
    width: '500px'
  },
  {
    text: 'Category',
    value: 'category'
  }
];

export default {
  name: 'ProductsPage',
  components: { DataTable },
  productsHeadersConfig: PRODUCT_TABLE_HEADER_CONFIG,
  data() {
    return {
      search: '',
      options: {},
      isLoading: false,
      products: []
    };
  },
  watch: {
    async options() {
      this.products = await this.getProducts();
    }
  },
  methods: {
    async getProducts() {
      try {
        this.isLoading = true;

        const { data } = await getProductsList();

        return data;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 80px;
}

.filter-box {
  width: 300px;
}
</style>
